import React, { useState, useEffect, useCallback } from "react";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";

import { AuthContext } from "./component/context";
import Authentication from "./component/Authentication";
import ProtectedPage from "./component/protectedPage";

let logoutTimer;

awsExports.oauth.redirectSignIn = `${window.location.origin}/`;
awsExports.oauth.redirectSignOut = `${window.location.origin}/`;
Amplify.configure(awsExports);


function App() {

  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const login = useCallback((token, expirationTime) => {
    setToken(token);
    const expiration =
      expirationTime || new Date(new Date().getTime() + 1000 * 60 * 60);
    setTokenExpirationDate(expiration);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        token,
        expirationTime: expiration.toISOString()
      })
    );
  }, []);


  const logout = useCallback(() => {
    setToken(null);
    localStorage.removeItem("userData");
    //show google button
  }, []);

  //hook to check if something is there in localStorage and logs user in accordingly
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expirationTime) > new Date()
    ) {
      login(storedData.token, new Date(storedData.expirationTime));
    }
  }, [login]);

  //new useEffect hook to set the timer if the expiration time is in future otherwise we clear the timer here
  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
      //show goole button
    }
  }, [token, logout, tokenExpirationDate]);

  return (

    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        login: login,
        logout: logout
      }}
    >
      <Authentication />
      <ProtectedPage/>
    </AuthContext.Provider>

  );
}

export default App;
