import React from "react";
import logo from "../static/DIMO_digital_logo.png";
const DIMOlogo = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "2rem" }}>
      <img src={logo} alt="logo.png" style={{ width: "35%" }}></img>
    </div>
  );
};

export default DIMOlogo;
