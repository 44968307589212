/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "users-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "devices_to_locations-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "attendance-dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "faceimagestore-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "userapi",
            "endpoint": "https://i3mhh4ioz6.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "devicelocationapi",
            "endpoint": "https://6kvu4il9zd.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "attendanceapi",
            "endpoint": "https://2kz91w67ik.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:9476fa91-c836-4f06-bda7-4f4713a5e759",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_9bcrQTLpP",
    "aws_user_pools_web_client_id": "17dnfd39mnir57pim8l4enevdb",
    "oauth": {
        "domain": "dimofaceregcee83533-cee83533-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://dev.d19dte99fs7u3u.amplifyapp.com/,https://faceregpoc.dimodigital.lk/",
        "redirectSignOut": "http://localhost:3000/,https://dev.d19dte99fs7u3u.amplifyapp.com/,https://faceregpoc.dimodigital.lk/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "predictions": {
        "identify": {
            "identifyEntities": {
                "proxy": false,
                "region": "us-east-1",
                "celebrityDetectionEnabled": true,
                "defaults": {
                    "collectionId": "rekognition-dev",
                    "maxEntities": 1
                }
            }
        }
    }
};


export default awsmobile;
