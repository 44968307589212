import React from "react";
import { MDBContainer, MDBFooter } from "mdbreact";

const FooterPage = () => {
  return (
    <MDBFooter className="font-small pt-4 mt-4">
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          {/* &copy; {new Date().getFullYear()}  */}
          All Rights Reserved <br />
          <b>
            {" "}
            <a href="https://dimodigital.lk/">Powered by DIMO digital</a>
          </b>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};

export default FooterPage;
