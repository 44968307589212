import React, { useContext, useState } from "react";
import GoogleButton from "react-google-button";
import { API, Auth } from "aws-amplify";
import Amplify from "aws-amplify";

import awsExports from "../aws-exports";
import { AuthContext } from "./context";
import DIMOlogo from "./DIMOlogo";
import FooterPage from "./Footer";
import Footerfront from "./Footerfront";

awsExports.oauth.redirectSignIn = `${window.location.origin}/`;
awsExports.oauth.redirectSignOut = `${window.location.origin}/`;
Amplify.configure(awsExports);

function Authentication() {
  const [display, setdisplay] = useState(false);
  const authContext = useContext(AuthContext);

  const loginHandler = (e) => {
    //authContext.login();
    //API call to server...
    e.preventDefault();
    console.log("Google button clicked");
    Auth.federatedSignIn({ provider: "Google" });

    // const userResponse = {
    //   token: "abjd2323jb443jbbb"
    // };

    // authContext.login(userResponse.token);
  };

  // const logoutHandler = () => {
  //   authContext.logout();
  // };
  setTimeout(() => {
    setdisplay(true);
  }, 7000);

  return (
    <div>
      {!authContext.isLoggedIn && (
        <div>
          <DIMOlogo />
          <div style={styles.googleBtn}>
            <GoogleButton
              style={{ display: display ? "" : "none" }}
              onClick={loginHandler}
            />
            <div style={{ display: !display ? "" : "none" }}>Loading ...</div>
          </div>
          <Footerfront />
        </div>
      )}
      {/* {authContext.isLoggedIn && (
        <button className="logout" onClick={logoutHandler}>
          Logout
        </button>
      )} */}
    </div>
  );
}

const styles = {
  googleBtn: {
    marginTop: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}
export default Authentication;
