import React from "react";
import { MDBContainer, MDBFooter } from "mdbreact";

const Footerfront = () => {
  return (
    <MDBFooter className="font-small " style={styles.footer}>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          {/* &copy; {new Date().getFullYear()}  */}
          All Rights Reserved <br />
          <b>
            {" "}
            <a href="https://dimodigital.lk/">Powered by DIMO digital</a>
          </b>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};

const styles = {
  footer: {
    bottom: 0,
    position: "absolute",
    width: "100%",
    background: "#2b3f8d",
  },
};
export default Footerfront;
